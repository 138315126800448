import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box'; // Missing import for Box
import useCookie from '../utils/getCookie';

const FeedbackDialog = ({ open, onClose }) => {
  const [rating, setRating] = useState(0); // Default rating is set to 0
  const [thoughts, setThoughts] = useState('');
  const [followUp, setFollowUp] = useState('1');
  const [validationMsg, setValidationMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(''); // New state for error message

  const token = useCookie('_athtxn');

  const handleSubmit = async () => {
    if (rating === 0 || !thoughts.trim()) {
      // Check if rating or thoughts are missing
      setErrorMsg('Please provide a rating and some thoughts.');
      return;
    }

    try {
      const response = await fetch('https://api.joblixx.com/v1/user/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': process.env.REACT_APP_CSRF_TOKEN,
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          rating,
          thoughts,
          followUp,
        }),
      });

      if (response.ok) {
        setValidationMsg(true);
        setErrorMsg(''); // Clear error message if the request was successful
        setTimeout(() => {
          onClose(); // Close the dialog after showing the thank you message
        }, 2000);
      } else {
        setErrorMsg('Failed to submit feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setErrorMsg('An error occurred. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" sx={{ minHeight: '300px' }} fullWidth>
    {!validationMsg ? (
    <>
      <DialogTitle>
        Give feedback
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogContent>
          {errorMsg && (
            <Typography color="error" variant="body2" gutterBottom>
              {errorMsg}
            </Typography>
          )}
          <Typography variant="h6" gutterBottom>
            What do you think of Joblixx?
          </Typography>
          <Rating
            name="feedback-rating"
            size="large"
            value={rating}
            onChange={(event, newValue) => setRating(newValue)}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            margin="normal"
            label="Do you have any thoughts you'd like to share?"
            value={thoughts}
            onChange={(e) => setThoughts(e.target.value)}
          />
          <Typography variant="subtitle1" gutterBottom>
            May we follow up on your feedback?
          </Typography>
          <RadioGroup
            row
            value={followUp}
            onChange={(e) => setFollowUp(e.target.value)}
          >
            <FormControlLabel value="1" control={<Radio />} label="Yes" />
            <FormControlLabel value="0" control={<Radio />} label="No" />
          </RadioGroup>
        </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ width: '85%', mx: 'auto', mb: 2 }}
        >
          Submit
        </Button>
      </DialogActions>
    </>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Typography align="center">
            Thank you for reporting this. We'll investigate and take the necessary action.
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default FeedbackDialog;
