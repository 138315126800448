import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// assets
import EditOutlined from '@ant-design/icons/EditOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import IdcardOutlined from '@ant-design/icons/IdcardOutlined';
import WalletOutlined from '@ant-design/icons/WalletOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import QuestionCircleOutlined from '@ant-design/icons';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

export default function ProfileTab() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabUrls = {
    0: '/profile',
    1: '/job-tracker',
    2: 'saved-jobs',
    3: '/plans'
  };

  const handleListItemClick = (index) => {
    if (tabUrls.hasOwnProperty(index)) {
      setSelectedIndex(index);
      window.location.href = tabUrls[index];
    } else {
      console.error(`No URL found for index: ${index}`);
    }
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={() => handleListItemClick(0)}>
        <ListItemIcon>
          <IdcardOutlined style={{ fontSize: 'medium' }} />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 1} onClick={() => handleListItemClick(1)}>
        <ListItemIcon>
          <EditOutlined style={{ fontSize: 'medium' }} />
        </ListItemIcon>
        <ListItemText primary="Applications" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={() => handleListItemClick(2)}>
        <ListItemIcon>
          <BookmarksOutlinedIcon style={{ fontSize: 'medium' }} />
        </ListItemIcon>
        <ListItemText primary="Saved Jobs" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 3} onClick={() => handleListItemClick(3)}>
        <ListItemIcon>
          <WalletOutlined style={{ fontSize: 'medium' }} />
        </ListItemIcon>
        <ListItemText primary="Plans" />
      </ListItemButton>
      {/* Uncomment if needed */}
      {/* <ListItemButton selected={selectedIndex === 4} onClick={() => handleLogout()}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton> */}
    </List>
  );
}

ProfileTab.propTypes = { handleLogout: PropTypes.func };
