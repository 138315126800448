import React, { useState, useEffect } from 'react';
import { useMediaQuery, Button, Snackbar, Typography, Box } from '@mui/material';

const InstallPwaPrompt = () => {
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches;
    if (isPWA) return;

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      if (isSmallScreen) {
        setShowInstallMessage(true);
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [isSmallScreen]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      try {
        deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the PWA installation');
        } else {
          console.log('User dismissed the PWA installation');
        }
      } catch (error) {
        console.error('Error during installation:', error);
      } finally {
        setDeferredPrompt(null);
        setShowInstallMessage(false);
      }
    }
  };

  return (
    <>
      {showInstallMessage && (
        <Snackbar
          open={showInstallMessage}
          sx={{
            '& .MuiSnackbarContent-root': {
              backgroundColor: 'primary.dark',
              color: '#ffffff',
              display: 'flex',
              flexDirection: 'column', // Ensure vertical alignment
              alignItems: 'center',
            },
          }}
          message={
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              <Typography>
                Install the app for a better experience!
              </Typography>
              <Button
                size='small'
                color="primary"
                variant="contained"
                onClick={handleInstallClick}
                sx={{ width: '80%', mt: 2 }} // Ensure 80% width and spacing from the text
              >
                Install
              </Button>
            </Box>
          }
          onClose={() => setShowInstallMessage(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Ensure Snackbar is centered at the bottom
        />
      )}
    </>
  );    
};

export default InstallPwaPrompt;
